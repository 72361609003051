<template>
  <div
    class="modal modal__right"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    v-if="show"
    :class="{ show: show == true }"
    :style="{ display: 'block' }"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                @click="$emit('close')"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Create Task Template
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form__item">
                    <label class="form__label">name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter name"
                      v-model="task.templateName"
                    />
                  </div>
                </div>
              </div>

              <ul id="accordion">
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#conditions"
                    aria-expanded="false"
                    aria-controls="conditions"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      conditions
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="conditions"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <label class="form__label">target</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="task.target"
                        >
                          <option value="" disabled selected>
                            Select type
                          </option>
                          <option value="Customer">Customer</option>
                          <option value="Groups">Groups</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#notes"
                    aria-expanded="false"
                    aria-controls="notes"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Content
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="notes" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          id=""
                          cols="5"
                          rows="5"
                          class="input form__input"
                          placeholder="Product Descriptions"
                          v-model="task.templateBody"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="row mt-5 d-none">
                <div class="col-12">
                  <div class="form__item">
                    <label class="form__label">placeholder</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="task.placeholder"
                    >
                      <option value="__Select option__" disabled selected>
                        __Select option__
                      </option>
                      <option value="Opt in">Opt-in</option>
                      <option value="Opt out">Opt-out</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="button"
                    data-dismiss="#addModal"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    save changes
                  </button>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="$emit('close')"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "createTaskTemplate",
  props: {
    close: Function,
    show: Boolean,
  },
  data() {
    return {
      task: {
        target: "",
        templateName: "",
        templateBody: "",
      },
    };
  },
};
</script>
